.filepond--credits {
  display: none !important;
}

.hover-overlay {
  position: absolute;
  top: 83%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 200;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.1);
  padding: 2px;
}

.doc-container:hover .hover-overlay{
  display: flex;
  opacity: 1;
}



.doc-container {

  width: 10%;
}

.prev-button,
.next-button {
  padding: 10px 20px;
  color: #000000;
  border: none;
  background-color: white;
  cursor: pointer;
 
}

