*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  color: darkgray;
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}
*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
#userLog::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  color: darkgray;
  width: 24px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
  display: block !important;
}
#userLog::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
.ps-sidebar-container::-webkit-scrollbar {
  display: none;
}

.loader-pulse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: load-pulse 1.5s infinite linear;
}

@keyframes load-pulse {
  0% {
    transform: scale(0.25);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
